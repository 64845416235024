.popup {
  box-sizing: border-box;
  min-width: 192px;

  &.visible {
    .arrow {
      transform: rotate(-180deg);
    }
  }
}

.containerLabel {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
}

.label {
  margin-right: var(--space-s);

  font-size: var(--size-n);
  line-height: 1.5;
  white-space: nowrap;
}

.arrow {
  width: 10px;
  height: 6px;
  margin-left: var(--space-xxs);

  background-image: url('iconArrow.svg');
  background-repeat: no-repeat;
  background-size: contain;

  transition: transform 0.2s;
}

.options {
  margin: var(--space-s) var(--space-s-neg) 0;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-xxs) var(--space-s);

  color: var(--color-dark-gray);

  cursor: pointer;

  &:hover {
    background-color: var(--color-light-gray);
  }

  &.selected {
    color: var(--color-black);

    &:after {
      display: block;
      width: 13px;
      height: 16px;

      background-image: url('iconCheck.svg');
      background-repeat: no-repeat;
      background-size: contain;

      content: '';
    }
  }
}
