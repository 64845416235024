.popup {
  box-sizing: border-box;
  min-width: 192px;
  padding: 0 0 var(--space-s);

  &.visible {
    .arrow {
      transform: rotate(-180deg);
    }
  }
}

.containerLabel {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-s) var(--space-s) var(--space-l);

  cursor: pointer;
}

.label {
  font-size: var(--size-n);
  line-height: 1.5;
  white-space: nowrap;
}

.arrow {
  width: 10px;
  height: 6px;
  margin-left: var(--space-xxs);

  background-image: url('iconArrow.svg');
  background-repeat: no-repeat;
  background-size: contain;

  transition: transform 0.2s;
}

.scroller {
  width: 100vw;
  max-width: 740px;
  height: 100vh;
  max-height: 354px;
}

.content {
  position: relative;

  overflow: hidden;

  &.column1 {
    column-count: 1;
  }

  &.column2 {
    column-count: 2;
  }

  &.column3 {
    column-count: 3;
  }

  &.column4 {
    column-count: 4;
  }
}

.group {
  margin-bottom: var(--space-l);
}
