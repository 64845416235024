.buttons {
  --button-height: 42px;

  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs);
  height: var(--button-height);
  margin-right: var(--space-l);
  overflow-y: hidden;

  & .button {
    height: var(--button-height);
    padding: 0 var(--space-s);

    font-weight: 400;
    font-size: var(--size-s);
    line-height: 1.5;
  }

  & .button.switcher {
    padding-left: var(--space-xs);
  }
}

.switcher:hover {
  & .switch {
    background-color: var(--color-dark-gray);
    border-color: var(--color-dark-gray);

    &.actived {
      background-color: var(--color-black);
      border-color: var(--color-black);

      &:after {
        background-color: var(--color-white);
      }
    }
  }
}

.switchGroup {
  display: flex;
  flex-shrink: 0;
  align-items: center;

  font-size: var(--size-s);
  line-height: 1.5;
}

.switch {
  margin-right: var(--space-xs);
}

.allFiltersBtn {
  font-size: var(--size-s);
}

.stickyFilters {
  .allFiltersBtn,
  .button {
    font-size: var(--size-s);
  }

  .switch {
    margin-right: var(--space-xs);
  }
}

@media (--desktop) {
  .buttons {
    margin-right: 0;
  }
}

@media (--mobile-m) {
  .button.secondary {
    display: none;
  }
}

@media (--mobile) {
  .buttons {
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    overflow: visible;

    & .allFiltersBtn,
    & .button {
      width: 160px;
      min-width: 160px;
    }

    & .switcher {
      justify-self: end;
    }
  }
}
