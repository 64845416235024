.labels {
  display: flex;
  align-items: center;
}

.label {
  margin-right: var(--space-xl);

  &:last-child {
    margin-right: 0;
  }
}

@media (--mobile) {
  .labels {
    justify-content: space-between;
  }

  .label {
    margin-right: var(--space-s);
  }
}
