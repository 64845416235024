.sections {
  margin-top: var(--space-xxl);
}

.section {
  padding: var(--space-xxb) 0 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.delimiter {
  composes: main from 'containers';

  margin-top: var(--space-e);

  &:before {
    display: block;
    width: 100%;

    border-bottom: 1px solid var(--color-medium-gray);

    content: '';
  }
}

@media (--desktop-m) {
  .section {
    padding: var(--space-xb) 0 0;

    &:first-child {
      padding-top: 0;
    }
  }

  .delimiter {
    margin-top: var(--space-xb);
  }
}

@media (--desktop) {
  .catalog {
    --columns: 3;
  }

  .section {
    padding: var(--space-xb) 0 0;

    &:first-child {
      padding-top: var(--space-xs);
    }
  }
}

@media (--mobile-m) {
  .section {
    padding: var(--space-b) 0 0;

    &:first-child {
      padding-top: 0;
    }
  }

  .sections {
    margin-top: var(--space-xxl);
  }

  .delimiter {
    display: none;
  }
}
