.item {
  display: inline-block;
  width: calc((100% / var(--columns)) - var(--gap-h));
  padding: 0 calc(var(--gap-h) / 2) var(--gap-v);

  vertical-align: top;

  &.withPlaceholder {
    &:before {
      display: block;
      padding: 0 0 33.7%;

      content: '';
    }
  }

  &.double {
    width: calc((100% / (var(--columns) / 2)) - var(--gap-h));
  }

  &.bannerRow {
    width: calc(100% - var(--gap-h));
  }
}

@media (--mobile) {
  .row.isInSpotlight {
    .item.double {
      width: calc((100% / var(--columns)) - var(--gap-h));
    }
  }
}
