.wrapper {
  position: absolute;
  top: 90px;
  right: 0;
  left: 0;
  z-index: 0;

  padding-bottom: var(--space-xs);

  background-color: var(--color-white);
  box-shadow: 0 1px 0 0 var(--color-light-gray);
  transform: translate3d(0, -100%, 0);

  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;

  &.enterActive {
    z-index: 1;

    transform: translate3d(0, -1px, 0);
  }

  &.enterDone {
    z-index: 1;

    transform: translate3d(0, -1px, 0);
  }

  &.exitActive {
    z-index: 0;

    transform: translate3d(0, -100%, 0);
  }
}

.container {
  composes: main from 'containers';
}

.filtersWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.actions {
  display: flex;
  align-items: center;
}

.count {
  color: var(--color-dark-gray);
  white-space: nowrap;
}

.wrapperLabels {
  display: flex;
  align-items: center;
}

.selectedValues {
  min-height: calc(var(--size-xs) * 1.5);
  margin-top: var(--space-xs);
  overflow: hidden;

  font-size: var(--size-xs);
  line-height: 1.5;
}

@media (--desktop) {
  .wrapper {
    position: relative;
    top: 0;
  }
}

@media (--mobile) {
  .wrapperLabels {
    flex-direction: column;
    align-items: stretch;
  }

  .selectedValues {
    margin-right: var(--space-s-neg);
    margin-left: var(--space-s-neg);
    padding: 0 var(--space-s);
  }
}
