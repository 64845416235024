.catalog {
  --columns: 4;
  --gap-h: var(--space-l);
  --gap-v: var(--space-xxb);
  composes: main from 'containers';
}

.list {
  display: block;
  margin-right: calc(var(--gap-h) / -2);
  margin-bottom: calc(var(--gap-v) * -1);
  margin-left: calc(var(--gap-h) / -2);
}

.delimiter {
  padding: 0 calc(var(--gap-h) / 2);
}

@media (--desktop-m) {
  .catalog {
    --gap-v: var(--space-b);
  }
}

@media (--desktop) {
  .catalog {
    --columns: 3;
    --gap-h: var(--space-n);
  }
}

@media (--mobile-m) {
  .catalog {
    --columns: 2;
  }
}

@media (--mobile) {
  .catalog {
    --gap-h: var(--space-xs);
    --columns: 2;
    overflow: hidden;

    &.isInSpotlight {
      --columns: 1;
    }

    &:not(.isInSpotlight) {
      .list {
        margin-right: var(--space-n-neg);
        margin-left: var(--space-n-neg);
      }

      .delimiter {
        margin-right: var(--space-s);
        margin-left: var(--space-s);
      }
    }
  }
}
