.mainWrapper {
  composes: main from 'containers';
}

.breadcrumbs {
  margin-bottom: var(--space-m);
}

.menu {
  margin-top: var(--space-xl);
  padding-top: var(--space-m);

  border-top: 1px solid var(--color-medium-gray);
}

.banner {
  position: relative;

  max-width: var(--max-content-width);
  margin: 0 auto;

  cursor: default;

  &.scrolling {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: -2;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.content {
  position: relative;

  background-color: var(--color-white);

  &.withBanner {
    padding-top: var(--space-b);
  }
}

.bannerHelper {
  position: absolute;
  z-index: -1;

  display: block;
  width: 100%;
  height: 100%;

  background-color: var(--color-white);

  transform: translate3d(0, 0, 0);
}

@media (--mobile-m) {
  .breadcrumbs {
    margin-bottom: var(--space-n);
  }

  .menu {
    margin-top: var(--space-l);
    padding-top: var(--space-m);
  }

  .content {
    &.withBanner {
      padding-top: var(--space-l);
    }
  }
}

@media (--mobile) {
  .banner {
    padding: 0;
  }
}
