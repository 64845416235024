.dropdown {
  position: relative;

  &.visible {
    .arrow {
      transform: rotate(-180deg);
    }
  }

  & .popup {
    top: var(--space-s-neg);

    transform: none;

    &.left {
      right: var(--space-s-neg);
      left: auto;
    }

    &.right {
      right: auto;
      left: var(--space-s-neg);
    }
  }
}

.enter {
  opacity: 0;
}

.enterDone {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitDone {
  opacity: 0;
}

.containerLabel {
  position: relative;

  display: flex;
  align-items: center;

  cursor: pointer;
}

.label {
  font-size: var(--size-n);
  line-height: 1.5;
  white-space: nowrap;
}

.arrow {
  width: 10px;
  height: 6px;
  margin-top: 1px;
  margin-left: var(--space-xxs);

  background-image: url('arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;

  transition: transform 0.2s;
}

@media (--mobile-m) {
  .dropdown {
    & .popup {
      right: 0;
      left: auto;
    }
  }
}

@media (--mobile) {
  .dropdown {
    position: static;
  }
}
