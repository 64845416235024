.values {
  min-height: 21px;
}

.gallery {
  margin: 0 var(--space-xxs-neg);
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--space-xs);
}

.linkWrapper {
  font-size: var(--size-xs);
  line-height: 1.5;
}

.selectedValue {
  display: inline-block;
  padding: 0 var(--space-xxs);

  color: var(--color-dark-gray);
  font-size: var(--size-xs);
  line-height: 1.5;
  vertical-align: baseline;

  cursor: pointer;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--color-grafit);

    &:after {
      opacity: 1;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:after {
    display: inline-block;
    width: 8px;
    height: 9px;
    margin-left: var(--space-xxs);

    vertical-align: baseline;

    background: url('./icons/remove.svg') no-repeat center/contain;
    opacity: 0.5;

    transition: opacity 0.2s ease-in-out;

    content: '';
  }
}

.content {
  position: relative;

  display: inline-block;

  &:hover {
    &:before {
      position: absolute;
      top: 50%;

      width: 100%;

      border-bottom: 1px solid var(--color-grafit);

      content: '';
    }
  }
}
