.message {
  padding: var(--space-xb) var(--space-s);
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: var(--size-xl);
  font-family: var(--font-hero);
  line-height: 1.5;
  text-align: center;
}

.icon {
  margin-right: var(--space-n);
}

.text {
  margin-top: var(--space-s);

  text-align: center;
}

@media (--mobile-m) {
  .message {
    padding: var(--space-xxl) var(--space-s);
  }
}

@media (--mobile) {
  .icon {
    margin: 0 0 var(--space-n);
  }

  .title {
    flex-direction: column;
  }
}
