.title {
  min-height: 1.5em;
  margin-bottom: var(--space-l);

  font-weight: 500;
  font-size: var(--size-xxl);

  font-family: var(--font-hero);
  line-height: 1.5;
}

.delimeter {
  margin-bottom: var(--space-xxb);
}

.delimeter::after {
  display: block;

  border-bottom: 1px solid var(--color-medium-gray);

  content: '';
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-xl);
  }

  .delimeter {
    margin-bottom: var(--space-b);
  }
}

@media (--mobile-m) {
  .title {
    font-size: var(--size-l);
  }
}
