.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 125px;

  padding: 0 var(--gap-half);

  color: var(--color-black);
}

.wrapperIcon {
  display: flex;
  justify-content: center;
  height: 80px;
}

.icon {
  width: 100%;
}

.title {
  margin-top: var(--space-n);

  font-size: var(--size-n);
  line-height: 1.5;
}

.count {
  padding-left: 5px;

  line-height: 1;
}

@media (--mobile-m) {
  .item {
    width: auto;
  }

  .title {
    margin-top: 0;

    color: var(--color-dark-gray);
  }

  .active .title {
    color: var(--color-black);
  }

  .count {
    padding-left: 0;

    font-size: var(--size-xxs);
  }
}
