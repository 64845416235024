.subcategories {
  --gap: var(--space-xl);
  --gap-half: calc(var(--gap) / 2);
  --gap-half-neg: calc(var(--gap-half) * -1);

  margin: 0 var(--gap-half-neg);
}
.itemsWrapper {
  margin: 0 var(--gap-half-neg);
}

.gallery {
  height: auto;

  .galleryItem {
    vertical-align: top;
  }
}

.track {
  margin-top: var(--space-l);
}

@media (--desktop) {
  .subcategories {
    --gap: var(--space-xxl);
  }
}

@media (--mobile-m) {
  .subcategories {
    --gap: var(--space-m);
  }

  .track {
    margin-top: var(--space-l);
  }

  .itemsWrapper {
    margin-bottom: var(--space-l);
  }
}
