.constructorStub {
  cursor: pointer;
}

.iconWrapper {
  position: relative;

  box-sizing: border-box;
  width: 100%;
}

.icon {
  position: absolute;
  top: 0;
  left: 50%;

  width: 65.57%;

  background-color: unset;

  border: 1px solid var(--color-medium-gray);
  border-radius: 50%;
  transform: translate3d(-50%, 0, 0);

  aspect-ratio: '1:1';
}

.iconImg {
  top: 50%;
  left: 50%;

  width: 70%;
  object-fit: contain;

  transform: translate(-50%, -50%);
}

.linkWrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--space-xxl);
}

.discount {
  position: absolute;
  top: 83%;
  z-index: 2;

  width: 100%;

  color: var(--color-dark-gray);
  font-size: var(--size-n);
  line-height: 1.5;
  text-align: center;
}

@media (--desktop-m) {
  .icon {
    width: 65.37%;
  }

  .discount {
    top: 80%;
  }
}

@media (--desktop) {
  .icon {
    width: 65.5%;
  }
}
