.galleryWrapper {
  margin: 0 var(--space-xxb-neg);
  padding: 0 var(--space-xxb);
  overflow: hidden;
}

.navigationLink {
  display: inline-block;
  padding: var(--space-xxs) 0;

  color: var(--color-dark-gray);
  font-size: var(--size-l);
  line-height: 1.5;
  vertical-align: top;

  border-bottom: 1px solid transparent;

  transition: all 0.2s;

  &:hover {
    color: var(--color-grafit);

    border-color: var(--color-grafit);
  }

  &.active {
    color: var(--color-black);

    border-color: var(--color-black);
  }
}

.arrowTriple {
  width: 23px;
  height: 20px;
  margin-right: 10px;
}

.gallery {
  margin: 0 var(--space-n-neg);
}

.navigationItem {
  padding: 0 var(--space-n);
}

@media (--desktop-m) {
  .galleryWrapper {
    margin: 0 var(--space-xxl-neg);
    padding: 0 var(--space-xxl);
  }

  .navigationLink {
    font-size: var(--size-m);
    line-height: 1.5;
  }
}

@media (--mobile) {
  .gallery {
    margin: 0 var(--space-xs-neg);
  }

  .navigationItem {
    padding: 0 var(--space-xs);
  }
}

@media (--mobile-m) {
  .galleryWrapper {
    margin: 0 var(--space-s-neg);
    padding: 0 var(--space-s);
  }

  .navigationLink {
    font-size: var(--size-n);
    line-height: 1.5;
  }
}
