.wrapper {
  --gap: var(--space-s);
  --card-width: 272px;
  --card-height: 310px;
  --outline-gap: var(--space-xs);
  --outline-gap-half: calc(var(--outline-gap) / 2);
  --outline-padding: calc(var(--outline-gap) - 1px);
  position: relative;

  padding: var(--gap);
}

.container {
  position: relative;
  z-index: 1;

  box-sizing: border-box;
  width: var(--card-width);
  height: var(--card-height);
  overflow: hidden;

  border-radius: 10px;
  cursor: pointer;
}

.outline {
  position: absolute;
  top: var(--outline-gap-half);
  left: var(--outline-gap-half);

  width: var(--card-width);
  height: var(--card-height);
  padding: var(--outline-padding);

  border: 1px solid transparent;
  border-radius: 10px;

  transition: border-color 200ms;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.text {
  position: relative;

  padding: var(--space-n);

  color: var(--color-black);
  line-height: 1.5;

  text-align: left;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-xs);
}

.title {
  font-weight: 500;
  font-size: 30px;
  font-family: var(--font-hero);
}

.subtitle {
  font-size: var(--size-m);
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;

  background-color: var(--color-white);
  border: 1px solid var(--color-dark-gray);
  border-radius: 4px;
  opacity: 0;

  transition: border-color 200ms, background-color 200ms, opacity 200ms;
}

.mark {
  width: 13px;
  height: 10px;

  transition: stroke 200ms;

  fill: none;
  stroke: var(--color-dark-gray);
  stroke-width: 2px;
}

@media (--desktop-m) {
  .wrapper {
    --card-width: 189px;
    --card-height: 215px;
  }

  .text {
    padding: var(--space-s);
  }

  .title {
    font-size: var(--size-xl);
  }

  .subtitle {
    font-size: var(--size-n);
  }
}

@media (--mobile-m) {
  .wrapper {
    --card-width: 160px;
    --card-height: 180px;
    --gap: 7.5px;
    --outline-gap: var(--space-xxs);
  }

  .text {
    padding: var(--space-xs);
  }

  .header {
    margin-bottom: var(--space-xxs);
  }

  .title {
    font-size: var(--size-m);
  }

  .subtitle {
    font-size: var(--size-s);
  }
}

@media (hover: hover) {
  .container:hover .check {
    opacity: 1;
  }
}

.container.selected {
  & + .outline {
    border-color: var(--color-grafit);
  }

  & .check {
    background-color: var(--color-grafit);
    border-color: var(--color-grafit);
    opacity: 1;
  }

  & .mark {
    stroke: var(--color-white);
  }
}
