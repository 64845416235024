.button.main {
  height: 42px;
  padding: 0 var(--space-s);
}

.button.main:active {
  .iconFilters {
    background-image: url('icons/filters-black.svg');
  }
}

.iconFilters {
  width: 28px;
  height: 18px;

  background-image: url('icons/filters-white.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.button.secondary {
  .iconFilters {
    background-image: url('icons/filters-black.svg');
  }
}
