.filtersWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: var(--space-m);
}

.wrapperActions {
  display: flex;
  flex-direction: column;
}

.actions {
  display: flex;
  align-items: center;
}

.count {
  color: var(--color-dark-gray);
  white-space: nowrap;
}

.wrapperLabels {
  display: flex;
  align-items: center;
}

.buttonMattresses {
  display: block;

  font-weight: 500;
  white-space: nowrap;
}

.iconMattresses {
  width: 30px;
  height: 30px;
  margin-right: var(--space-xs);

  background-image: url('icons/mattresses.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-top: var(--space-n);

  .labels {
    margin-left: auto;
  }
}

.selectedValues {
  margin-top: var(--space-n);
  overflow: hidden;
}

@media (--mobile-m) {
  .filtersWrapper {
    margin-top: var(--space-s);
  }
}

@media (--mobile) {
  .filtersWrapper {
    display: block;
  }

  .wrapperLabels {
    flex-direction: column;
    align-items: stretch;
  }

  .buttonMattresses {
    margin-right: 0;
    padding-top: var(--space-n);
  }

  .popularLinksWrapper {
    max-width: 100%;
    margin-top: var(--space-n);
  }

  .bottom {
    flex-direction: column-reverse;

    .labels {
      width: 100%;
      margin-left: 0;
    }
  }

  .selectedValues {
    margin-right: var(--space-s-neg);
    margin-left: var(--space-s-neg);
    padding: 0 var(--space-s);
  }
}
