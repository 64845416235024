.popularLinks {
  margin-bottom: var(--space-xxs-neg);

  .link {
    display: inline-block;

    vertical-align: baseline;
  }
}

.label {
  margin-right: var(--space-n);

  color: var(--color-dark-gray);

  font-size: var(--size-s);
  line-height: 1.5;

  white-space: nowrap;
}

.link {
  margin-right: var(--space-m);
  margin-bottom: var(--space-xxs);

  font-size: var(--size-s);
  line-height: 1.5;

  &:last-child {
    margin-right: 0;
  }

  &:first-letter {
    text-transform: uppercase;
  }
}

@media (--mobile) {
  .popularLinks {
    box-sizing: border-box;
    max-width: 100vw;
    height: 23px;
    margin-right: var(--space-s-neg);
    margin-left: var(--space-s-neg);
    overflow: hidden;

    white-space: nowrap;
  }

  .container {
    height: 40px;
    padding-right: var(--space-s);
    padding-left: var(--space-s);
    overflow-x: auto;
    overflow-y: hidden;
  }

  .label {
    margin-right: var(--space-s);
  }

  .link {
    margin-right: var(--space-xs);
    margin-bottom: 0;
  }
}
