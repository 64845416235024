.wrapper {
  overflow: hidden;
}

.gallery {
  margin: 0 var(--space-s-neg);
}

.track {
  margin-top: var(--space-n);
}

@media (--mobile-m) {
  .gallery {
    margin: 0 calc(var(--space-s-neg) / 2);
  }
}
