.section {
  --columns: 4;
  --gap-h: var(--space-l);
  --gap-v: var(--space-xxb);
}

.container {
  composes: main from 'containers';
}

.titleWrapper {
  display: flex;
  align-items: baseline;
}

.title {
  margin: 0 var(--space-xs) 0 0;

  font-weight: 500;
  font-size: var(--size-b);
  line-height: 1.5;
}

.priceMin {
  font-size: var(--size-m);
  font-family: var(--font-main);
  line-height: 1.5;
}

.items {
  display: block;
  margin-bottom: calc(var(--gap-v) * -1 + var(--space-n));
}

.gallery {
  margin-right: calc(var(--gap-h) / -2);
  margin-left: calc(var(--gap-h) / -2);
}

.track {
  margin-top: var(--space-l);
}

.item {
  display: inline-block;
  width: calc((100% / var(--columns)) - var(--gap-h));
  padding: 0 calc(var(--gap-h) / 2) var(--space-b);
}

.stub {
  vertical-align: top;
}

@media (--desktop-m) {
  .section {
    --gap-v: var(--space-b);
  }

  .title {
    font-size: var(--size-xxl);
  }

  .items {
    margin-bottom: calc(var(--gap-v) * -1);
  }
}

@media (--desktop) {
  .section {
    --columns: 3;
    --gap-h: var(--space-n);
  }

  .title {
    font-size: var(--size-xl);
  }

  .priceMin {
    font-size: var(--size-n);
    line-height: 1.5;
  }
}

@media (--mobile-m) {
  .container {
    overflow: hidden;
  }

  .title {
    font-size: var(--size-l);
  }

  .items {
    margin: 0;
  }

  .item {
    min-width: 300px;
    padding: 0 var(--space-xs);
  }

  .priceMin {
    font-size: var(--size-s);
    line-height: 1.5;
  }
}

@media (--mobile) {
  .title {
    font-size: var(--size-l);
  }

  .titleWrapper {
    flex-wrap: wrap;
  }

  .priceMin {
    font-size: var(--size-s);
    line-height: 1.5;
  }
}

@media (--mobile-xxs) {
  .item {
    min-width: 260px;
  }
}
