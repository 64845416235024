.filtersWrapper {
  display: flex;
  gap: var(--space-n);
  align-items: center;
  justify-content: space-between;

  padding-top: var(--space-m);
}

.actions {
  --button-height: 42px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  height: var(--button-height);
  overflow-y: hidden;
}

.count {
  color: var(--color-dark-gray);
  white-space: nowrap;
}

.wrapperLabels {
  display: flex;
  align-items: center;
}

.bottom {
  margin-top: var(--space-n);
}

.selectedValues {
  margin-top: var(--space-n);
  overflow: hidden;
}

@media (--mobile-m) {
  .filtersWrapper {
    margin-top: var(--space-s);
  }
}

@media (--mobile) {
  .filtersWrapper {
    display: block;
  }

  .wrapperLabels {
    flex-direction: column;
    align-items: stretch;
    margin-top: var(--space-n);
  }

  .selectedValues {
    margin-right: var(--space-s-neg);
    margin-left: var(--space-s-neg);
    padding: 0 var(--space-s);
  }
}
